(function ($, Drupal, window, document, undefined) {
    // Avoid `console` errors in browsers that lack a console.
    // https://github.com/h5bp/html5-boilerplate/blob/master/dist/js/plugins.js
    var method;
    var noop = function () {};
    var methods = [
            'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
            'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
            'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
            'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }


    // To understand behaviors, see https://drupal.org/node/756722#behaviors
    Drupal.behaviors.p6bootstrap = {
        attach: function(context, settings) {
            // Check if it is front page.
            // @see stanford_bootstrap_preprocess_page() in template.php
            var isFront = false;
            if ((typeof(settings.StanfordBootstrap) !== 'undefined') && settings.StanfordBootstrap !== null) {
                if ((typeof(settings.StanfordBootstrap.isFront) !== 'undefined') && settings.StanfordBootstrap.isFront !== null) {
                    isFront = settings.StanfordBootstrap.isFront;
                }
            }

            // Put browser agent information into the body class.
            if($.browser.msie) {
                $('body').addClass('msie');
                $('body').addClass('ie' + $.browser.version.substring(0, 1));
            }
            if($.browser.webkit) {
                $('body').addClass('webkit');
            }
            if($.browser.mozilla) {
                $('body').addClass('mozilla');
            }

            // Slidedown for views.
            $('.slide-down-trigger').click(function(event) {
                $(this).parents('.views-row').find('.slide-down-target').slideToggle();
                $(this).toggleClass('expanded');
                event.preventDefault();
            });

            // Main nav hover
            var navMenu = $('nav.p6-main-menu', context);
            $('> ul > li', navMenu).bind('mouseenter mouseleave touchstart touchend', function() {
                $(this).toggleClass('hover');
            });

            navMenu.find('li.active-trail').addClass('opened');
            $('span.arrow', navMenu).on('click', function() {
                var parent = $(this).closest('li');
                var submenuContainer = $(this).siblings('ul');


                if (parent.hasClass('opened')) {
                    parent.removeClass('opened');
                    submenuContainer.slideToggle('300');
                }
                else {
                    // Close previously opened item.
                    var prevOpenedItem = parent.siblings('li.opened, li.active-trail:visible');
                    if (prevOpenedItem.length) {
                        var prevSubmenuContainer = $('> ul', prevOpenedItem);

                        prevSubmenuContainer.slideUp('300', function() {
                            prevOpenedItem.removeClass('opened');

                            // Closing sub menus
                            prevOpenedItem
                                .find('.opened')
                                .removeClass('opened');
                            prevOpenedItem
                                .find('ul')
                                .hide();
                        });
                    }

                    parent.addClass('opened');
                    submenuContainer.slideToggle();
                }
            });

            // Responsive menu.
            $('body').on('click ontouchstart', function(e) {
                var target = $(e.target);

                if (!target.is('.nav-toggle') && !target.is('span.arrow') && $('#navbarCollapse').is('.nav-active')) {
                    $('#navbarCollapse').removeClass('nav-active');
                    $('.nav-toggle').addClass('collapsed');
                    $('#navbarCollapse').slideUp();
                }
            });

            $('body').on('click ontouchstart', '.nav-toggle', function() {
                $(this).toggleClass('collapsed');
                $('#navbarCollapse').toggleClass('nav-active');
                $('#navbarCollapse').slideToggle();
            });

            // Search button
            var searchForm = $('form#search-block-form', context);
            if (searchForm.length) {
                var searchButton = $('button', searchForm);
                var searchFormItem = $('.form-item', searchForm);
                var searchInput = $('input', searchFormItem);

                searchForm.on('submit', function(e) {
                    if (!searchFormItem.is(':visible') || searchFormItem.hasClass('transitioning')) {
                        e.preventDefault();
                        return false;
                    }
                });

                searchButton.on('click', function() {
                    if (!searchFormItem.is(':visible')) {
                        searchFormItem
                            .removeClass('stretchLeftHide')
                            .addClass('stretchLeft')
                            .addClass('transitioning');
                    }

                    $('body').one('animationend webkitAnimationEnd', function() {
                        if (searchInput.length && searchInput.is(':visible')) {
                            searchInput.get(0).focus();
                            searchFormItem.removeClass('transitioning');
                        }
                    });
                });

                $('html').on('click', function(e) {
                    if (!searchForm.is(e.target) && searchForm.has(e.target).length === 0)    {
                        if (searchFormItem.is(':visible')) {
                            searchFormItem
                                .removeClass('stretchLeft')
                                .addClass('stretchLeftHide')
                                .addClass('transitioning');

                            $('body').one('animationend webkitAnimationEnd', function() {
                                searchFormItem.removeClass('stretchLeftHide transitioning');
                            });
                        }
                    }
                });
            }


            // Fix Status report table.
            $('table.system-status-report', context).once('table-system-status', function() {
                $(this).addClass('table');
            });
        }
    };


    // Enable Bootstrap for tableDragChangedWarning.
    Drupal.theme.prototype.tableDragChangedWarning = function() {
        return '<div class="tabledrag-changed-warning messages warning alert alert-warning">' + Drupal.theme('tableDragChangedMarker') + ' ' + Drupal.t('Changes made in this table will not be saved until the form is submitted.') + '</div>';
    };
})(jQuery, Drupal, this, this.document);
